@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');

.font-alex-brush {
  font-family: 'Alex Brush', cursive;
}

.font-allura {
  font-family: 'Allura', cursive;
}

.font-baskerville {
  font-family: 'Baskerville', serif;
}

.font-great-vibes {
  font-family: 'Great Vibes', cursive;
}

.bg-custom {
  background-image: url('./assets/images/Background-no-flower.png');
  background-size: cover;
  background-position: center;
}

.animate-fadeIn {
  animation: fadeIn 2s ease-in-out forwards;
}

.text-uppercase {
  text-transform: uppercase;
}

.name-wedding {
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'Baskerville', serif;
  letter-spacing: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
